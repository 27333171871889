
@import "../node_modules/bootstrap/scss/bootstrap";
.custom-class {
  display: none;
}
@include media-breakpoint-down(sm) {
  .player-page{
    align-items: center!important;
    text-align: center;
  }
  
  .powered-by {
    align-self: flex-start;
  }
  .info-container{
    justify-content: center!important;
  }
}


.App {
  text-align: center;
  font-family: 'Poppins';
}
h1{
  margin:0;
}
.App-header {
  background-color: rgb(22, 22, 22);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 1;
}

.player-page{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100vh;
}
#streamlinePlayer{
  width: 100%;
  overflow-y: hidden;
  position: relative;
  padding-bottom: 56.25%;
}
#streamlinePlayer > video{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  object-fit: cover;
}
#streamlinePlayer > iframe{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  object-fit: cover;
}
#startButton {
  min-width: '160px';
  min-height: '200px'
}
.btn-start {
  text-align: center;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  transition: opacity 1s ease;
  padding: 20px 0;
}
.btn-start > div {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 130px;
  min-width: 130px;
}
.btn-start > p {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.98;
  margin-top: 10px;
}
.img-play {
width: 100px;
height: 100px;
opacity: 0.8;

}
.img-play:hover {
opacity: 1;
transition: opacity 0.5s ease;
}
.hidden {
  display: none!important;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.disabled{
  opacity: 0.1;
  pointer-events: none;
  transition: opacity 0.5s ease;
}
.outer-circle{
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.27);
  border-radius: 100%;
  width: 128px;
  height: 128px;
  display: block;
}
.inner-circle {
  position: absolute; 
  background-color: rgba(255, 255, 255, 0.12);
  border: 2px solid #FFFFFF;
  border-radius: 100%;
  width: 102px;
  height: 102px;
}
.triangle {
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 18px solid white;
  z-index: 1;
}
.tagline{
  display: flex;
  align-items: baseline;
  flex-direction: column;
  z-index: 1;
  padding: 20px 0;
}


.tagline > p{
  color: #FFFFFF;
  font-size: 28px;
  line-height: 34px;
  margin-top: 0;
}

.info-container{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  opacity: 1;
  transition: opacity 1s ease; 

}
.brand {
  padding: 30px 0;
  text-align: left;
  z-index: 10;
  width: 250px;
}
.brand > img {
 width: 100%;
}
.powered-by {
  text-align: left;
  padding: 0 15px;
  margin-bottom: 15px;
  border-left: 2px solid #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;

}
.powered-by > img {
  width: 160px;
  height: auto;
  margin: 5px 0 9px;

}

.background-image{    
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.6;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  object-fit: cover;
  transition: opacity 2s ease-in-out 1s;
} 
.fullscreen-toggle{
  float: right;
  margin-right: none;
  margin-left: auto;
  margin-top: 5px;
  cursor: pointer;
  font-size: 14px;
  background: rgb(34, 34, 34);
  padding: 5px 9px;
  border-radius: 3px;
  color: #ccc;
  transition: 0.5s all ease;
}
.fullscreen-toggle:hover{
  background: rgb(58, 58, 58);
  color: #fff;
  transition: 0.5s all ease;
}
.stream-controls{
  position: absolute;
  height: 39px;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 100;
  cursor: pointer;
  background: transparent;
  transition: all 0.5s ease;
}
.streamcontrols:hover{
  background: #000;
  transition: all 0.5s ease;
}
.stream-controls:hover > .fullscreen-toggle{
  padding-top: 0px !important;
  opacity: 1;
  transition: opacity 0.5s ease;
}

#installing-video-container {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 1920px;
  height: 1080px;
  object-fit: cover;
}

#installing-video {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  //object-fit: cover;
  //transition: opacity 2s ease-in-out 1s;
}

.hint {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  min-height: 100px;
  will-change: opacity;
  z-index: 10;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25%;
color: #fff!important;
}
.minus-z{
  z-index: -10;
}

.carousel-item > p{
margin-bottom: 0!important;
}

@media only screen and (min-aspect-ratio: 19/9) and (max-height: 750px) {
  .brand {
    width: 120px;
  }

  #startButton {
    min-height: 80px;
    min-width: 80px;
  }

  .tagline > p{
    color: #FFFFFF;
    font-size: 14px;
    line-height: 34px;
    margin-top: 0;
  }

  h1 {
    font-size: 22px;
  }
}